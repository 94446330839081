import React from 'react'
import { Container, Navbar, Nav } from 'react-bootstrap'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import Seo from '../Seo';
// code syntax-highlighting theme
// feel free to change it to another one
import 'prismjs/themes/prism-twilight.css'

// main site style
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.scss'

const TemplateWrapper = ({ children, title, description, lang, meta, keywords, data, topMargin }) => {
  let user
  if (typeof window !== 'undefined') {
    user = window.netlifyIdentity && window.netlifyIdentity.currentUser()
  }

  return (
    <StaticQuery query={pageQuery} render={data => (
      <div className='App'>
        <Seo
          title={title}
          description={description}
          lang={lang}
          meta={meta}
          keywords={keywords}
        />

        <Navbar bg="primary" expand="xs" variant="dark" className="text-dark" style={{'display': 'block'}}>
          <Container>
            <Navbar.Brand href="/" className="text-dark">
              <h3 className="font-sans-serif">
                <span className="font-weight-bold">{data.site.siteMetadata.title}</span>
              </h3>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="main-navbar-nav">
              <span dangerouslySetInnerHTML={{__html: `
                <svg width="25px" height="23px" viewBox="0 0 25 23" version="1.1" xmlns="http://www.w3.org/2000/svg">
                  <g id="hamburger">
                    <rect x="0" y="0" width="25" height="3" fill="#29292a"></rect>
                    <rect x="10" y="10" width="15" height="3" fill="#29292a"></rect>
                    <rect x="5" y="20" width="20" height="3" fill="#29292a"></rect>
                  </g>
                </svg>
              `}}></span>
            </Navbar.Toggle>
            <Navbar.Collapse id="main-navbar-nav">
              <Nav className="ml-auto">
                {user && (
                  <Nav.Link href="/admin" className="text-dark" style={{ paddingRight: '12px' }}>admin</Nav.Link>
                )}

                {data.nav.edges.map(({node}) => (
                  <Nav.Link
                    key={node.link}
                    href={node.link}
                    className="text-dark"
                    style={{ paddingRight: '12px' }}
                  >{node.name.toLowerCase()}</Nav.Link>
                ))}
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>

        <div className={topMargin === false ? 'pageContent' : 'pageContent topMargin'}>
          {children}
        </div>
        <div className='footer'>
          <Container>
            <Navbar>
              <Nav className="mr-auto font-weight-light">
                {data.nav.edges.map(({node}, idx) => (
                  <Nav.Item key={idx}>
                    <Nav.Link href={node.link} className={ idx === 0 ? 'pl-0 text-primary' : ' text-primary' }>
                      {node.name.toLowerCase()}
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>

              <Nav>
                <Nav.Item>
                  <a
                    href='https://feedly.com/i/subscription/feed%2Fhttps%3A%2F%2Fjspizziri.com%2Frss.xml'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <img
                      id='feedlyFollow'
                      src='https://s3.feedly.com/img/follows/feedly-follow-logo-green_2x.png'
                      alt='follow us in feedly'
                      width='20'
                      height='20'
                      style={{ marginTop: '-5px' }}
                    />
                  </a>
                </Nav.Item>

                <Nav.Item>
                  <a
                    href='https://jspizziri.com/rss.xml'
                    target='_blank'
                    rel='noopener noreferrer'
                    className='text-reset'
                  >
                    <i className="fa fa-rss" aria-hidden="true"></i>
                  </a>
                </Nav.Item>
              </Nav>
            </Navbar>
          </Container>
        </div>
      </div>
    )} />
  )
}

TemplateWrapper.propTypes = {
  children: PropTypes.object,
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
  topMargin: PropTypes.bool,
}

const pageQuery = graphql`
  query LayoutIndexQuery {
    site {
      siteMetadata {
        title
      }
    }

    nav: allNavYaml(
      sort: {fields:weight order: ASC}
    ) {
      edges {
        node {
          name
          link
          weight
        }
      }
    }
  }
`

export default TemplateWrapper
